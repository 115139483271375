<footer>
  <div class="footer wrapper">
    <div class="footer__row">
    </div>

    <div class="footer__row">
      <div class="footer__colum">
      </div>
      <div class="footer__colum">
        <span class="footer__text">© 2023 FTBS Group All rights reserved.</span>
      </div>
    </div>
  </div>
</footer>
